// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  static targets = ["accept"]

  initialize() {
    if(!this.hasAccepted()) {
      this.showNotice();
    }
  }

  hasAccepted() {
    return Cookies.get('has-accepted-cookies') === "true"
  }

  accept(e) {
    e.preventDefault();
    this.setCookie();
    this.hideNotice();
  }

  setCookie() {
    Cookies.set('has-accepted-cookies', 'true', { expires: 365 });
  }

  showNotice() {
    document.getElementsByTagName("body")[0].classList.remove("has-accepted-cookies");
  }

  hideNotice() {
    document.getElementsByTagName("body")[0].classList.add("has-accepted-cookies");
  }
}