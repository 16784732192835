// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["messageBox"]

  connect() {
    this.form = document.getElementsByClassName("g-form")[0]
  }

  onMessageSent(ev) {
    let [data, status, xhr] = ev.detail;

    this.messageBoxTarget.insertAdjacentHTML("afterend", xhr.response)

    if (xhr.response.includes("Email sent")) {
      dataLayer.push({
        event: "message"
      });
    }
  }

  clearCurrentAlerts(ev) {
    for (let notice of this.form.getElementsByClassName('c-notice')) {
      notice.parentElement.removeChild(notice)
    }
    for (let alert of this.form.getElementsByClassName('c-alert')) {
      alert.parentElement.removeChild(alert)
    }
  }
}
