// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  toggleMenu(ev) {
    ev.preventDefault();
    
    const menuSidebar = document.getElementsByClassName("g-menu")[0];

    if (menuSidebar.className.includes("--open")) {
      menuSidebar.className = "g-menu";
    } else {
      menuSidebar.className = "g-menu g-menu--open";
    }
  }
}
