import { Controller } from 'stimulus'

// As per https://github.com/nathanvda/cocoon/issues/555
// We need this package because the cocoom gem does not work straight out of the package
// with webpack and this causes it not to work in Rails 6
// Installed cocoon-js via yarn
// There is another option https://github.com/nathanvda/cocoon/issues/555#issuecomment-640256856
// But that seemed more hacky so I stuck to this current one

import 'cocoon-js-vanilla'

export default class extends Controller {
  static targets = ['tagsContainer', 'seriesContainer'];

  itemAdded(ev) {
    const existingItemsLen = this.relationContainer(ev).querySelectorAll(".nested-fields").length;

    if(existingItemsLen >= 4) {
      let item = this.relationContainer(ev).querySelector(".links")
      item.style.display = "none";
    }
  }

  itemRemoved(ev) {
    const existingItemsLen = this.relationContainer(ev).querySelectorAll(".nested-fields").length;

    if(existingItemsLen <= 5) {
      let item = this.relationContainer(ev).querySelector(".links")
      item.style.display = "block";
    }
  }

  relationContainer(ev) {
    switch(ev.target.dataset.container) {
      case "tags": return this.tagsContainerTarget
      case "series": return this.seriesContainerTarget
    }
  }
}
