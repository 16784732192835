import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DirectUpload } from "@rails/activestorage";
import RUG, { DropArea, DragArea } from 'react-upload-gallery';

// Component imports
import ImageCard from '../ImageCard/ImageCard';

// Style imports
import 'react-upload-gallery/dist/style.css';

const ImageUploader = ({
  existingUploads,
  inputsContainerId,
  relationName,
  fileTypes,
  imageLimit,
  maxFileSize,
  minImageWidth,
  maxImageWidth,
  minImageHeight,
  maxImageHeight,
  primary,
}) => {
  const [state, setState] = useState({
    warnings: [],
    uploadError: false,
  });

  const handleWarning = (type, rules) => {
    const updatedWarnings = [...state.warnings];

    switch(type) {
      case 'accept':
        updatedWarnings.push('Invalid image format, must be either jpg, jpeg or png');
        break;

      case 'limit':
        updatedWarnings.push(`You are only allowed to upload a maximum of ${rules.limit} images`);
        break;

      case 'size':
        updatedWarnings.push(`Images must have a file size below ${rules.size}KB`);
        break;

      case 'minWidth': case 'minHeight':
        updatedWarnings.push(`Images must be at least ${rules.width.min}x${rules.height.min}`);
        break;

      case 'maxWidth': case 'maxHeight':
        updatedWarnings.push(`Images must not be larger than ${rules.width.max}x${rules.height.max}`);
        break;

      default:
    }

    setState({...state, warnings: updatedWarnings});
  };

  const handleError = () => {
    setState({...state, uploadError: true});
  };

  const customRequest = ({ uid, file, send, action, headers, onProgress, onSuccess, onError }) => {
    let upload = new DirectUpload(file, action);

    upload.create((error, blob) => {
      if (error) {
        onError(uid, { action, error })
      } else {
        // Create the input that the form will submit the blob signed it with
        ImageUploader.addBlobAsAttachment(blob, relationName, inputsContainerId, imageLimit);

        // Let the uploader show the uploaded asset
        onSuccess(uid, { source: ImageUploader.blobUrlFor(blob) });
      }
    });

    return {};
  };

  const removeDeletedImageData = (image) => {
    const signedId = image.source.split('/')[4];
    const imageSignedIdInput = document.querySelector(`input[value='${signedId}']`);

    return imageSignedIdInput.parentNode.removeChild(imageSignedIdInput);
  };

  const reorderImageInputs = (images) => {
    for(let image of images) {
      const signedId = image.source.split('/')[4];
      const imageSignedIdInput = document.querySelector(`input[value='${signedId}']`);

      if(!imageSignedIdInput)
        continue

      imageSignedIdInput.parentNode.removeChild(imageSignedIdInput)
      ImageUploader.addBlobAsAttachment({signed_id: signedId}, relationName, inputsContainerId, imageLimit)
    }
  };

  return (
    <>
      <RUG
        accept={fileTypes}
        inOrder={true}
        rules={{
          limit: imageLimit,
          size: maxFileSize,
          width: {
            min: minImageWidth,
            max: maxImageWidth,
          },
          height: {
            min: minImageHeight,
            max: maxImageHeight,
          },
        }}
        onWarning={handleWarning}
        onError={handleError}
        action="/rails/active_storage/direct_uploads"
        initialState={existingUploads ? JSON.parse(existingUploads) : undefined}
        onChange={(images) => reorderImageInputs(images)}
        onDeleted={(image) => removeDeletedImageData(image)}
        customRequest={customRequest}
        header={({openDialogue}) => (
          <DropArea>
            {isDrag => (
              <div style={{ ...style.dropzoneContent, ...(isDrag ? { border: '3px solid #000', opacity: '0.5', outline: '0' } : {}) }}>
                <svg style={style.dropzoneIcon} width="43" height="28" viewBox="0 0 43 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M34.5 28L11 28V27.9531C10.6711 27.9841 10.3375 28 10 28C4.47715 28 0 23.7467 0 18.5C0 13.904 3.43553 10.0702 8.00002 9.19003L8 9.17241C8 4.10663 12.2533 0 17.5 0C21.4795 0 24.8876 2.36254 26.3016 5.7142C27.2718 5.25614 28.356 5 29.5 5C33.5924 5 36.9193 8.27774 36.9986 12.3512C40.4736 13.3555 43 16.4001 43 20C43 24.2603 39.4617 27.7428 35 27.9864V28L34.5 28Z" fill="#32409A"/>
                  <path d="M20.364 9.29289C20.7545 8.90237 21.3876 8.90237 21.7782 9.29289L27.1421 14.6569L25.7279 16L22.0711 12.4142V23H20.0711L20.0711 12.4142L16.4142 15.9142L15 14.5L20.364 9.29289Z" fill="white"/>
                </svg>
                <p style={style.dropzoneText}>Drop files here or</p>
                <a href='/' style={style.dropzoneLink} onClick={(e) => { e.preventDefault(); openDialogue();}} role='button'>Browse to choose files</a>
                <small style={{ marginTop: '30px', textAlign: 'center' }}>For the best results, images should be in a 4:3 ratio of 1200 x 900 pixels and in webp or jpg format</small>
              </div>
            )}
          </DropArea>
        )}
      >
        {state.warnings.length > 0 && (
          <ul style={style.alertList}>
            {state.warnings.map((warning, i) => (
              <li style={style.alert} key={i}>{warning}</li>
            ))}
          </ul>
        )}

        {state.uploadError && (
          <p style={style.alert}>Image(s) failed to upload - please retry.</p>
        )}

        <DragArea>
          {(image) => (
            <ImageCard image={image} emphasis={primary} />
          )}
        </DragArea>
      </RUG>
    </>
  );
};

ImageUploader.addBlobAsAttachment = (blob, relationName, inputsContainerId, imageLimit) => {
  const signedId = blob.signed_id;

  if(document.querySelector(`input[value='${signedId}']`))
    return

  const input = document.createElement("input");

  input.type = "hidden";
  if(imageLimit === 1) {
    input.name = `listing[${relationName}]`;
  } else {
    input.name = `listing[${relationName}][]`;
  }
  input.value = signedId;

  document.getElementById(inputsContainerId).appendChild(input);

  return input;
};

ImageUploader.blobUrlFor = (blob) => {
  return `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
};

const style = {
  dropzoneContent: {
    border: '1px dashed #A8A9A9',
    outline: '2px solid transparent',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '54px',
    borderRadius: '3px',
    marginBottom: '24px',
    transition: 'background-color ease .3s, border-style ease .3s, opacity ease .3s',
  },
  dropzoneText: {
    margin: 0,
    color: '#000',
    fontSize: '16px',
    lineHeight: '24px',
  },
  dropzoneIcon: {
    marginBottom: '8px',
  },
  dropzoneLink: {
    color: '#32409A',
  },
  alertList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  alert: {
    borderRadius: '5px',
    fontSize: '16px',
    padding: '12px 18px',
    color: '#CA2323',
    backgroundColor: '#FCF4F4',
    marginBottom: '16px',
    border: '1px solid #ca232333',
  },
};

ImageUploader.defaultProps = {
  fileTypes: ['jpg', 'jpeg', 'png'],
};

ImageUploader.propTypes = {
  existingUploads: PropTypes.string,
  relationName: PropTypes.string,
  inputsContainerId: PropTypes.string,
  uploadAction: PropTypes.string,
  fileTypes: PropTypes.array.isRequired,
  imageLimit: PropTypes.number,
  maxFileSize: PropTypes.number,
  minImageWidth: PropTypes.number,
  maxImageWidth: PropTypes.number,
  minImageHeight: PropTypes.number,
  maxImageHeight: PropTypes.number,
  primary: PropTypes.bool,
};

export default ImageUploader;
