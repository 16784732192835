// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  countryCodeChanged(ev) {
    let select = ev.currentTarget
    let countryCodeSplit = select.options[select.selectedIndex].firstChild.textContent.split(" ")
    let phoneCode = countryCodeSplit[countryCodeSplit.length - 1]

    let prependedPhoneCodeHelper = document.getElementsByClassName("c-phone-code-prepend")[0]
    prependedPhoneCodeHelper.value = phoneCode

    prependedPhoneCodeHelper.nextElementSibling.firstChild.placeholder = ""
  }

  togglePrependCode(ev) {
    let prependedPhoneCodeHelper = document.getElementsByClassName("c-phone-code-prepend")[0]

    if(ev.type === "focus") {
      prependedPhoneCodeHelper.className = "c-phone-code-prepend c-phone-code-prepend--focused"
    } else {
      prependedPhoneCodeHelper.className = "c-phone-code-prepend"
    }
  }
}
