// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  toggleFavourite(ev) {
    ev.preventDefault()

    let listingId = ev.currentTarget.dataset.listingId
    let favouriteId = ev.currentTarget.dataset.favouriteId
    let button = ev.currentTarget
    let removeFromPage = ev.currentTarget.dataset.removeSavedFromPage || false

    if(favouriteId === "") {
      this.createFavourite(button, listingId)
    } else {
      this.deleteFavourite(button, removeFromPage)
    }
  }

  createFavourite(button, listingId) {
    setTimeout(() => {
      Rails.ajax({
        type: "POST",
        url: `/account/saved-listings?listing_id=${listingId}`,
        success: (result, status, xhr) => {
          button.classList.add("c-save--saved")
          button.dataset.favouriteId = result.favourite.id
        },
        error: (xhr, status, error) => { console.log(error) }
      })
    }, 100)
  }

  removeFavouriteFromPage(button) {
    let listingCard = button.closest(".c-listing")
    listingCard.parentNode.removeChild(listingCard)
  }

  deleteFavourite(button, removeFromPage) {
    let removeFavouriteFromPage = this.removeFavouriteFromPage
    setTimeout(() => {
      Rails.ajax({
        type: "DELETE",
        url: `/account/saved-listings/${button.dataset.favouriteId}`,
        success: (result, status, xhr) => {
          button.classList.remove("c-save--saved")
          button.dataset.favouriteId = ""
          removeFromPage && removeFavouriteFromPage(button)
        },
        error: (xhr, status, error) => { console.log(error) }
      })
    }, 100)
  }
}
