// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = []

  sortFilterChanged(ev) {
    let newFilter = ev.currentTarget.value
    let searchFilters = new URLSearchParams(window.location.search)

    searchFilters.set("search[sort_by]", newFilter)

    Turbolinks.visit(`?${searchFilters.toString()}`, { action: "replace" })
  }

  syncKeywords(ev) {
    let keywords = ev.currentTarget.value

    document.getElementById("keywords-sidebar").value = keywords
  }

  toggleOpenFilter(ev) {
    ev.preventDefault();

    const accordionTrigger = ev.currentTarget;
    const accordionWrapper = accordionTrigger.parentElement;

    if (accordionWrapper.className.includes("--open")) {
      accordionWrapper.className = "c-filter";
    } else {
      accordionWrapper.className = "c-filter c-filter--open";
    }
  }

  toggleFilterSidebar(ev) {
    ev.preventDefault();

    const filterSidebar = document.getElementsByClassName("g-sidebar")[0];

    if (filterSidebar.className.includes("--open")) {
      filterSidebar.className = "g-sidebar";
    } else {
      filterSidebar.className = "g-sidebar g-sidebar--open";
    }
  }
}
