// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    if(typeof(cloudinary) != "undefined") {
      this.galleryWidget()
    }
  }

  galleryWidget() {
    const gallery = cloudinary.galleryWidget({
      container: "#g-gallery",
      cloudName: this.data.get("name"),
      navigation: "always",
      loaderStyle: "circle",
      aspectRatio: "4:3",
      carouselStyle: "thumbnails",
      carouselLocation: "bottom",
      themeProps: {
        onPrimary: "#32409A"
      },
      thumbnailProps: {
        spacing: 5,
        selectedBorderWidth: 0
      },
      zoomProps: {
        type: "inline"
      },
      navigationButtonProps: {
        buttonShape: "square",
        iconColor: "white",
        color: "black"
      },
      mediaAssets: this.mediaAssets()
    });
    gallery.render();
  }

  mediaAssets() {
    const mediaAssets = [];
    const keys = JSON.parse(this.data.get("keys"));
    for (var key in keys) {
      mediaAssets.push({ publicId: keys[key], transformation: { crop: "fill", quality: "80", gravity: "center" } })
    }
    return mediaAssets;
  }
}