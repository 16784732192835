import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './ImageCard.scss';

const ImageCard = ({
    image,
    emphasis,
}) => {
    // Class definitions
    const baseClass = 'image-card';
    const editClass = image.error ? 'image-card--error' : '';
    const uploadingClass = image.uploading ? 'image-card--progress' : '';
    const emphasisClass = emphasis ? 'image-card--emphasis' : '';
    const classes = [baseClass, editClass, uploadingClass, emphasisClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            <div className="image-card__asset">
                {image.error && (
                    <a href="#" className="image-card__reload" onClick={(e) => {e.preventDefault(); image.refresh();}}>
                        <span className='accessible'>Retry upload</span>
                    </a>
                )}

                <img className="image-card__img" src={image.source} alt="" aria-hidden='true' />
            </div>

            <div className="image-card__meta">
                <p className="image-card__name">{image.name}</p>
                <p className="image-card__size">{image.size}</p>
            </div>

            <a href="#" className="image-card__delete" onClick={(e) => {e.preventDefault(); image.remove();}}>
                <span className='accessible'>Remove image</span>
            </a>

            <span className="image-card__reorder">
                <span className='accessible'>Reorder image</span>
            </span>
        </div>
    );
};

ImageCard.propTypes = {
    image: PropTypes.object.isRequired,
    emphasis: PropTypes.bool,
};

export default ImageCard;