// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    "priceAmount",
    "priceOnApplication",
  ]

  connect() {
    if(this.hasPriceAmountTarget) {
      this.togglePricing();
    }
    document.addEventListener("trix-file-accept", function(ev) {
      ev.preventDefault()
    })
  }

  toggleTerms(ev) {
    const listingId = ev.currentTarget.dataset.id
    let listingData = new FormData()
    listingData.append('listing[terms_and_conditions_accepted]', ev.currentTarget.checked)

    setTimeout(() => {
      Rails.ajax({
        type: "PATCH",
        url: `/account/listings/${listingId}/update_terms`,
        data: listingData,
        success: (result, status, xhr) => {

        },
        error: (xhr, status, error) => { console.log(error) }
      })
    }, 100)
  }

  togglePricing(ev) {
    this.priceAmountTarget.disabled = this.priceOnApplicationTarget.checked;
  }
}
